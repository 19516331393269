var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "new-order__card",
      class: { "new-order__card--active": _vm.card.active },
      on: { click: _vm.changeCard }
    },
    [
      _c("div", { staticClass: "new-order__card-brand" }, [
        _vm.card.brand === "visa"
          ? _c("i", { staticClass: "ri-visa-line" })
          : _vm.card.brand === "mastercard"
          ? _c("i", { staticClass: "ri-mastercard-line" })
          : _c("i", { staticClass: "ri-bank-card-line" })
      ]),
      _c("div", { staticClass: "new-order__card-title" }, [
        _c("div", { staticClass: "new-order__card-type" }, [
          _vm.card.cardName
            ? _c("span", [_vm._v(_vm._s(_vm.card.cardName) + " / ")])
            : _vm._e(),
          _vm._v(_vm._s(_vm.card.cardType) + " ")
        ]),
        _c("div", { staticClass: "new-order__card-number" }, [
          _vm._v("**** **** **** " + _vm._s(_vm.card.last4))
        ])
      ]),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-order__card-check" }, [
      _c("i", { staticClass: "ri-checkbox-circle-fill" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }