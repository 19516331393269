var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new-order__main" },
    [
      _vm.isLoading ? _c("Loader") : _vm._e(),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "new-order__main-title" }, [
            _vm._v("Choose Payment Method")
          ]),
          !_vm.cards.length
            ? _c("div", { staticClass: "new-order__part" }, [
                _c("div", { staticClass: "new-order__payment-title" }, [
                  _vm._v(
                    " Please add at least one payment method to proceed. You can have your personal or business payment option depending on the order type. "
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "new-order__button",
                    on: { click: _vm.showNewCard }
                  },
                  [
                    _c("i", { staticClass: "ri-add-circle-fill" }),
                    _c("span", { staticClass: "new-order__button-text" }, [
                      _vm._v("Add New Payment Method")
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm._l(_vm.cards, function(card) {
            return _c("Card", { key: card.id, attrs: { card: card } })
          }),
          _vm.cards.length && !_vm.NewCardShow
            ? _c(
                "div",
                {
                  staticClass: "new-order__button",
                  on: { click: _vm.showNewCard }
                },
                [
                  _c("i", { staticClass: "ri-add-circle-fill" }),
                  _c("span", { staticClass: "new-order__button-text" }, [
                    _vm._v("Add New Payment Method")
                  ])
                ]
              )
            : _vm._e(),
          _vm.NewCardShow ? _c("NewCard") : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }