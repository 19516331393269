<template>
  <div class="new-order__part">
    <div class="new-order__card-title">
      New payment option
    </div>
    <div class="new-order__card-subtitle">
      You can save as many cards as you’d like.
    </div>
    <div class="new-order__card-title">
      Is this business or personal card?
    </div>
    <div class="new-order__card-choice">
      <label class="radio__label">
        <input v-model="type" class="radio__input" type="radio" value="business" />
        <span class="radio__view"></span>
        <span class="radio__text">Business card</span>
      </label>
      <label class="radio__label">
        <input v-model="type" class="radio__input" type="radio" value="personal" />
        <span class="radio__view"></span>
        <span class="radio__text">Personal card</span>
      </label>
    </div>
    <form class="new-order__form">
      <label class="new-order__card-label new-order__card-label-line">
        <div id="card-number-element" class="field"></div>
      </label>
      <label class="new-order__card-label new-order__card-label-line">
        <input v-model="cardName" class="new-order__card-input" type="text" placeholder="Card Name" />
      </label>
      <label class="new-order__card-label">
        <div id="card-expiry-element" class="field"></div>
      </label>
      <label class="new-order__card-label is-hide" :class="{'is-show': isCvc}">
        <div id="card-cvc-element" class="field"></div>
      </label>
      <div id="addCvc" class="new-order__button--cvc" :class="{'is-hide': isCvc}">Add CVC</div>
      <div id="sendBtn" class="new-order__button">
        <span class="new-order__button-text">Save this method</span>
      </div>
    </form>
  </div>
</template>

<script>
import {loadStripe} from '@stripe/stripe-js'

export default {
  name: 'PublicNewOrderPayment',
  props: {
    pickup: {
      type: Object,
      default: null
    },
    dropOff: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      type: 'business',
      cardName: null,
      isCvc: false
    }
  },
  async mounted() {
    const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY)
    const elements = stripe.elements()

    const style = {
      base: {
        fontSize: '14px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }

    const cardNumberElement = elements.create('cardNumber', {style, showIcon: true})
    cardNumberElement.mount('#card-number-element')

    const cardExpiryElement = elements.create('cardExpiry', {style})
    cardExpiryElement.mount('#card-expiry-element')

    const btn = document.getElementById('sendBtn')
    btn.addEventListener(
      'click',
      async () => {
        const token = await stripe.createToken(cardNumberElement)
        const newCard = {
          token: token.token.id,
          cardType: this.type,
          cardName: this.cardName
        }
        this.$store.dispatch('card/add', newCard)
      },
      {once: true}
    )
    const addCvcBtn = document.getElementById('addCvc')
    addCvcBtn.addEventListener(
      'click',
      () => {
        this.isCvc = true
        const cardCvcElement = elements.create('cardCvc', {style})
        cardCvcElement.mount('#card-cvc-element')
      },
      {once: true}
    )
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
