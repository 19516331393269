<template>
  <div class="new-order__status">
    <div v-if="statusCreated" class="new-order__status-inner">
      <div class="new-order__status-icon new-order__status-icon--green"><i class="ri-checkbox-circle-fill"></i></div>
      <div class="new-order__status-title">Order booked!</div>
      <div class="new-order__status-subtitle">Your order has been book and waiting for approval.</div>
      <div class="new-order__status-buttons">
        <button class="button" @click="viewDeliveries">View my orders</button>
        <button v-if="!onlyPay" class="button button--fill" @click="newOrder">Book New Order</button>
      </div>
    </div>
    <div v-if="statusConfirm" class="new-order__status-inner">
      <div class="new-order__status-icon new-order__status-icon--orange">
        <i class="ri-indeterminate-circle-fill"></i>
      </div>
      <div class="new-order__status-title">Please confirm order</div>
      <div class="new-order__status-subtitle"></div>
      <div class="new-order__status-buttons">
        <button class="button button--green" @click="confirmPI">Confirm</button>
        <button class="button button--red" @click="cancelOrder">Cancel</button>
      </div>
    </div>
    <div v-if="statusCancel" class="new-order__status-inner">
      <div class="new-order__status-icon new-order__status-icon--red">
        <i class="ri-close-circle-fill"></i>
      </div>
      <div class="new-order__status-title">Order Canceled</div>
      <div class="new-order__status-subtitle"></div>
      <div class="new-order__status-buttons">
        <button class="button" @click="viewDeliveries">View my orders</button>
        <button v-if="!onlyPay" class="button button--fill" @click="newOrder">Book New Order</button>
      </div>
    </div>
    <div v-if="statusWaiting" class="new-order__status-inner">
      <div class="new-order__status-icon new-order__status-icon--blue">
        <i class="ri-money-dollar-circle-fill"></i>
      </div>
      <div class="new-order__status-title">Waiting for payment</div>
      <div class="new-order__status-subtitle"></div>
      <div class="new-order__status-buttons">
        <button class="button" @click="viewDeliveries">View my orders</button>
        <button class="button button--fill" @click="newOrder">Book New Order</button>
      </div>
    </div>
    <div v-if="statusError" class="new-order__status-inner">
      <div class="new-order__status-icon new-order__status-icon--red">
        <i class="ri-close-circle-fill"></i>
      </div>
      <div class="new-order__status-title">Payment Error</div>
      <div class="new-order__status-subtitle"></div>
      <div class="new-order__status-buttons">
        <button class="button" @click="viewDeliveries">View my orders</button>
        <button v-if="!onlyPay" class="button button--fill" @click="newOrder">Book New Order</button>
      </div>
    </div>
  </div>
</template>

<script>
import {loadStripe} from '@stripe/stripe-js'

export default {
  name: 'PublicNewOrderStatusModal',
  props: {
    result: {
      type: Object,
      default: null
    },
    onlyPay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: null,
      statusCreated: false,
      statusConfirm: false,
      statusCancel: false,
      statusWaiting: false,
      statusError: false,
      stripe: null
    }
  },
  async created() {
    const {status, id, pmID, clientSecret} = this.result
    this.id = id
    this.pmID = pmID
    this.clientSecret = clientSecret
    if (status === 'Created') this.statusCreated = true
    else if (status === 'Requires Confirmation') this.statusConfirm = true
    else if (status === 'Waiting for Payment') this.statusWaiting = true
    else if (status === 'Payment Error') this.statusError = true
  },
  methods: {
    viewDeliveries() {
      this.$store.commit('orders/reset')
      this.$store.dispatch('orders/fetch')
      this.$modal.hide('StatusModal')
      this.$modal.hide('NewOrderModal')
      if (this.$route.path !== '/public/orders') this.$router.push('/public/orders')
    },
    newOrder() {
      this.$modal.hide('StatusModal')
    },
    async confirmPayment() {
      await this.$store.dispatch('orders/confirm', this.id)
      this.statusConfirm = false
      this.statusCreated = true
    },
    // async cancelPayment() {
    //   await this.$store.dispatch('orders/cancel', this.id)
    //   this.statusConfirm = false
    //   this.statusCancel = true
    // },
    async cancelOrder() {
      this.statusConfirm = false
      this.statusCancel = true
    },
    async confirmPI() {
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY)
      const payment = await stripe.confirmCardPayment(this.clientSecret, {
        payment_method: this.pmID
      })
      if (payment.error) {
        alert(payment.error.message)
      } else {
        if (payment.paymentIntent.status === 'requires_capture') {
          // await this.$store.dispatch('orders/confirm', this.id)
          this.statusConfirm = false
          this.statusCreated = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
