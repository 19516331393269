<template>
  <div class="new-order__card" :class="{'new-order__card--active': card.active}" @click="changeCard">
    <div class="new-order__card-brand">
      <i v-if="card.brand === 'visa'" class="ri-visa-line"></i>
      <i v-else-if="card.brand === 'mastercard'" class="ri-mastercard-line"></i>
      <i v-else class="ri-bank-card-line"></i>
    </div>
    <div class="new-order__card-title">
      <div class="new-order__card-type">
        <span v-if="card.cardName">{{ card.cardName }} / </span>{{ card.cardType }}
      </div>
      <div class="new-order__card-number">**** **** **** {{ card.last4 }}</div>
    </div>
    <div class="new-order__card-check"><i class="ri-checkbox-circle-fill"></i></div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    card: {
      type: Object,
      default: null
    }
  },
  methods: {
    changeCard() {
      this.$store.commit('card/activate', this.card)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
