<template>
  <div class="new-order__main">
    <Loader v-if="isLoading" />
    <div class="container">
      <div class="new-order__main-title">Choose Payment Method</div>
      <div v-if="!cards.length" class="new-order__part">
        <div class="new-order__payment-title">
          Please add at least one payment method to proceed. You can have your personal or business payment option
          depending on the order type.
        </div>
        <div class="new-order__button" @click="showNewCard">
          <i class="ri-add-circle-fill"></i>
          <span class="new-order__button-text">Add New Payment Method</span>
        </div>
      </div>
      <Card v-for="card of cards" :key="card.id" :card="card" />
      <div v-if="cards.length && !NewCardShow" class="new-order__button" @click="showNewCard">
        <i class="ri-add-circle-fill"></i>
        <span class="new-order__button-text">Add New Payment Method</span>
      </div>
      <NewCard v-if="NewCardShow" />
    </div>
  </div>
</template>

<script>
import NewCard from './NewCard'
import Card from './Card'
import {mapState} from 'vuex'
import Loader from '@/components/Loader'

export default {
  name: 'PublicNewOrderNewCard',
  components: {NewCard, Card, Loader},
  props: {
    isLoading: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      NewCardShow: false
    }
  },
  computed: {
    ...mapState({
      cards: s => s.card.cards,
      activeCard: s => s.card.activeCard
    })
  },
  watch: {
    activeCard(card) {
      if (card) this.NewCardShow = false
    },
    cards() {
      this.NewCardShow = false
    }
  },
  created() {
    if (!this.cards.length) {
      this.$store.dispatch('card/fetch')
    }
  },
  methods: {
    showNewCard() {
      this.$store.commit('card/activate', null)
      this.NewCardShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
